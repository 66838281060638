/* eslint-env browser */
// formSubmit.mjs
import { API_URL, TIMEOUT_SUBMIT } from "./config.js";

const btnSubmit = document.getElementById("contact-form__btn-submit");

/**
 * Changes the appearance of the submit button to indicate
 * that the form is being processed.
 *
 * @param {boolean} processing - Indicates if the submission is being processed
 * @return {void}
 */
function setProcessingMode(processing) {
  if (processing) {
    btnSubmit.disabled = true;
    btnSubmit.textContent = "Sending...";
    btnSubmit.classList.add("btn--spinner");
  } else {
    btnSubmit.classList.remove("btn--spinner");
    btnSubmit.textContent = "Send";
    btnSubmit.disabled = false;
  }
}

export async function submitForm(formData) {
  try {
    // indicate the submission is being processed
    setProcessingMode(true);

    const objData = {
      name: formData.get("name"),
      email: formData.get("email"),
      phone: formData.get("phone"),
      message: formData.get("message"),
    };
    // console.log("Sending data: ", objData);

    // timeout request after specified time
    const timeoutPromise = new Promise((_, reject) =>
      setTimeout(
        () => reject(new Error("Submission timed out")),
        TIMEOUT_SUBMIT,
      ),
    );

    const response = await Promise.race([
      fetch(API_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(objData),
      }),
      timeoutPromise,
    ]);

    if (response instanceof Response) {
      // console.log("Response:", response);
      const responseData = await response.text();
      if (!response.ok) {
        // handle server error
        // console.log("Error message:", responseData);
        return {
          status: "error",
          message: responseData,
        };
      }
      // handle success
      // console.log("Server response:", responseData);
      return { status: "success", message: "Email sent successfully!" };
    }
  } catch (error) {
    return {
      status: "error",
      message: error.message,
    };
  } finally {
    setProcessingMode(false);
  }
}
