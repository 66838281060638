/* eslint-env browser */
// main.js
import { ALERT_TYPES, isModalOpen, showFormAlert } from "./formAlerts.mjs";
import { submitForm } from "./formSubmit.mjs";
import { FormValidation } from "./formValidation.mjs";

document.addEventListener("DOMContentLoaded", () => {
  const logo = document.getElementById("main-logo");
  const scroller = document.querySelector(".content-scroller");
  const sections = document.querySelectorAll(".section");
  const topNav = document.getElementById("top-nav");
  const menuHamburger = document.getElementById("menu-hamburger");
  const menuClose = document.getElementById("menu-close");
  const menuButtons = document.querySelectorAll(".menu-item");
  const contactButton = document.getElementById("btn__contact");
  const nextButton = document.getElementById("nav-btn__nextpg");
  const contactForm = document.getElementById("contact-form");

  let currentSectionIndex = 0;
  let isPrivacyTermsHidden = true; // show or hide Privacy & Terms block

  /***** FUNCTIONS FOR SECTION SCROLLING - WHEEL AND TOUCH HANDLERS *****/
  // event handler flags
  let isHandlingWheel = true;
  let isHandlingSwipe = true;
  let touchStartY = 0;

  function handleTouchStart(event) {
    // event.preventDefault();
    touchStartY = 0;
    isHandlingWheel = false;

    // Disable for desktop, if modal is open,
    // or handling touch events for nav buttons/inputs
    if (
      isHandlingSwipe &&
      window.innerWidth < 1024 &&
      !isModalOpen &&
      !event.target.classList.contains("nav-btn") &&
      !event.target.classList.contains("btn") &&
      !event.target.classList.contains("contact-input")
    ) {
      // handle touch-swipe
      touchStartY = event.touches[0].clientY;
      // console.log("touchStartY: " + touchStartY);
    }
    isHandlingWheel = true;
    isHandlingSwipe = true;
  }

  function handleTouchMove(event) {
    // event.preventDefault();

    // Disable for desktop, if modal is open, or handling touch events for nav buttons/inputs
    if (
      isHandlingSwipe &&
      window.innerWidth < 1024 &&
      !isModalOpen &&
      touchStartY &&
      event.changedTouches[0] &&
      !event.target.classList.contains("nav-btn") &&
      !event.target.classList.contains("btn") &&
      !event.target.classList.contains("contact-input")
    ) {
      // handle touch-swipe
      const touchEndY = event.changedTouches[0].clientY;
      const deltaY = touchEndY - touchStartY;
      // console.log("handleTouvhMove: " + touchStartY + "/" + deltaY);

      if (Math.abs(deltaY) > 0) {
        event.preventDefault();
        // Determine the direction of swiping
        navigate(deltaY > 0 ? -1 : 1);
      }

      touchStartY = 0;
      isHandlingWheel = true;
      isHandlingSwipe = true;
    }
  }

  // Function to handle mouse wheel events
  function handleWheel(e) {
    // Prevent the default behavior to avoid page scrolling
    e.preventDefault();

    // Disable handling wheel events for mobile screens
    if (window.innerWidth < 1024) return;

    // If handling wheel events is paused, do nothing
    if (!isHandlingWheel || isModalOpen) return;

    // Delay handling immediate next wheel events
    isHandlingWheel = false;
    isHandlingSwipe = false;

    // Determine the direction of scrolling
    const direction = e.deltaY > 0 ? 1 : -1;
    navigate(direction);

    // Re-enable handling wheel events after a timeout
    setTimeout(() => {
      isHandlingWheel = true;
      isHandlingSwipe = true;
    }, 2000);
  }

  /***** FUNCTIONS FOR SECTION NAVIGATION AND RENDERING *****/

  // Function that increment/decrements the current position and triggers UI update
  function navigate(dir) {
    if (dir === 1 && currentSectionIndex < sections.length - 1) {
      currentSectionIndex++;
    } else if (dir === -1 && currentSectionIndex > 0) {
      currentSectionIndex--;
    }
    // update UI
    renderSections();
  }

  // Function that sets the current section to a specified index
  // and triggers the UI update
  function navigateTo(index) {
    if (index !== currentSectionIndex) {
      currentSectionIndex = index;
      renderSections();
    }
  }

  // Function that handles updating UI on navigation change
  function renderSections() {
    // scroller dataset controls position
    scroller.dataset.section = String(currentSectionIndex);

    // Check if last section is active and hide nextButton
    nextButton.style.display =
      currentSectionIndex === sections.length - 1 ? "none" : "inline-block";

    // mark current section active
    sections.forEach((section, index) => {
      if (index === currentSectionIndex) {
        section.classList.add("active");
      } else {
        section.classList.remove("active");
      }
    });

    // mark current menu button active
    menuButtons.forEach((menuItem) => {
      if (menuItem.dataset.sectionId === String(currentSectionIndex)) {
        menuItem.classList.add("active");
      } else {
        menuItem.classList.remove("active");
      }
    });

    // for mobile, clear active service selection when Services section is shown
    if (currentSectionIndex === 1 && window.innerWidth < 768) {
      clearActiveService();
    }

    // close Privacy & Terms modal (if open)
    hidePrivacyTermsInfo();

    // close form modal (if open)
    const modalDlg = document.getElementById("contact-form-dialog");
    if (modalDlg.open) {
      modalDlg.close();
    }
  }

  /***** FUNCTIONS FOR SERVICES SECTION [1] LOGIC *****/

  const servicesList = document.getElementById("services-list");
  const serviceDetails = document.querySelectorAll(".service-details");

  // used for desktop resolution only - by default, select the first service item
  function setDefaultService() {
    // mark the first service as active
    const s1 = document.querySelector("li.service[data-id='s1']");
    s1.classList.add("active");
    // mark the first service detail as active
    const sd1 = document.getElementById("s1");
    sd1.classList.add("active");
  }

  // removes .active class from all services and service-detail elements
  function clearActiveService() {
    const activeServices = document.querySelectorAll("li.service.active");
    activeServices.forEach((service) => service.classList.remove("active"));
    const activeServiceDetails = document.querySelectorAll(
      ".service-details.active",
    );
    activeServiceDetails.forEach((detail) => detail.classList.remove("active"));
    // unhide the service list if it is hidden
    servicesList.classList.remove("hidden");
  }

  // service list item selection
  servicesList.addEventListener("click", (e) => {
    const selectedId = e.target.dataset.id;
    if (!selectedId) return;

    // for desktop & tablet resolutions, mark selected service list item as active
    if (window.innerWidth >= 768) {
      const selectedService = e.target;
      // if user clicked on the currently active list item, do nothing
      if (selectedService.classList.contains("active")) return;
      // unselect the previously active item
      const currentActiveService = document.querySelector(".service.active");
      currentActiveService?.classList.remove("active");
      // mark the selected item active
      selectedService?.classList.add("active");
    } else {
      // for mobile resolutions, hide the section title and services list,
      // sectionTitle.classList.add("hidden");
      servicesList.classList.add("hidden");
      // and show selected service detail
    }
    //clear active service and mark selected service detail as active
    const currentActiveDetail = document.querySelector(
      ".service-details.active",
    );
    currentActiveDetail?.classList.remove("active");
    // mark the selected item active
    const selectedDetail = document.getElementById(selectedId);
    selectedDetail?.classList.add("active");
  });

  // event listener for service-details click
  serviceDetails.forEach((detail) =>
    detail.addEventListener("click", () => {
      // for mobile resolution, unmark currently selected service description as active
      // and show section title and services list
      if (window.innerWidth < 768) {
        clearActiveService();
      }
    }),
  );

  /***** FUNCTIONS FOR CONTACT FORM - SECTION [4] *****/
  // form validation and submittion
  // replace 'Enter' keystrokes inside all input elements with 'Tab' keystrokes
  const inputFields = Array.from(
    contactForm.querySelectorAll("input, textarea"),
  );
  inputFields.forEach((field) => {
    if (field.tagName === "INPUT") {
      // get a handle to the next form field
      const nextFieldIndex = inputFields.indexOf(field) + 1;
      field.addEventListener("keydown", (e) => {
        if (e.key === "Enter") {
          e.preventDefault(); // Prevent the default behavior of the Enter key
          inputFields[nextFieldIndex]?.focus();
        }
      });
    }
  });

  // form validation
  const formVal = new FormValidation("contact-form");
  contactForm.addEventListener("submit", async (e) => {
    e.preventDefault();

    // validate the form
    const validationErrors = formVal.validateAllWithAutoConstraints();
    if (validationErrors.length > 0) {
      showFormAlert(
        ALERT_TYPES.ERROR,
        "Please correct the following:",
        validationErrors,
      );
      return;
    }
    // if all fields are valid, send the data to the server;
    // the fields values are trimmed during validation.
    const formData = new FormData(e.target);
    const submitResponse = await submitForm(formData);

    if (submitResponse.status === "error") {
      // use obfuscated email address
      showFormAlert(
        ALERT_TYPES.ERROR,
        submitResponse.message,
        "Please send us an email at: info&#64;<b>msg-</b>sowa&#46;dev",
      );
      return;
    }

    // on success reset the form and show success message
    contactForm.reset();
    showFormAlert(
      ALERT_TYPES.SUCCESS,
      "Your message has been sent",
      "Thank you!<br />We will get back you asap.",
    );
  });

  /***** FUNCTIONS FOR CONTROLLING THE PRIVACY & TERMS MODAL *****/

  // show/hide Privacy & Terms Info modal
  function showPrivacyTermsInfo() {
    if (!isPrivacyTermsHidden) return;
    // const contactsBlock = document.getElementById("contacts");
    // hide Contacts section content
    const contactsSectionContent = document.querySelector(
      "#section-contacts .section-content",
    );
    const termsBlock = document.getElementById("terms");
    contactsSectionContent.classList.add("hidden");
    termsBlock.classList.remove("hidden");
    isPrivacyTermsHidden = false;
  }

  function hidePrivacyTermsInfo() {
    if (isPrivacyTermsHidden) return;
    // unhide Contacts section content
    const contactsSectionContent = document.querySelector(
      "#section-contacts .section-content",
    );
    const termsBlock = document.getElementById("terms");
    termsBlock.classList.add("hidden");
    contactsSectionContent.classList.remove("hidden");
    isPrivacyTermsHidden = true;
  }

  /***************** EVENT LISTENERS ********************/

  // Mouse wheel events
  scroller.addEventListener("wheel", handleWheel);

  // Touch navigation events
  scroller.addEventListener("touchstart", (e) => handleTouchStart(e));

  scroller.addEventListener("touchmove", (e) => {
    if (e.changedTouches?.[0]) {
      handleTouchMove(e);
    }
  });

  // Mobile - hamburger and close menu buttons events
  menuHamburger.addEventListener("click", () => {
    topNav.classList.add("active");
    menuHamburger.style.display = "none";
    menuClose.style.display = "block";
  });

  function handleMobileMenuClose() {
    topNav.classList.remove("active");
    menuHamburger.style.display = "block";
    menuClose.style.display = "none";
  }

  menuClose.addEventListener("click", handleMobileMenuClose);

  // Logo click event (go back home)
  logo.addEventListener("click", () => navigateTo(0));

  // Bottom navigation buttons events
  nextButton.addEventListener("click", () => {
    navigate(1); // Pass 1 to go to the next section
  });

  // Top menu buttons click events
  menuButtons.forEach((menuItem) =>
    menuItem.addEventListener("click", () => {
      navigateTo(+menuItem.dataset.sectionId);
      if (window.innerWidth < 1024) handleMobileMenuClose();
    }),
  );

  // `Contact Us` button click event
  contactButton.addEventListener("click", (e) => {
    e.preventDefault();
    navigateTo(sections.length - 1); // go to the last section
  });

  // Warning card expand/collapse events
  const warningCard = document.getElementById("contact-warning");
  warningCard?.addEventListener("click", () => {
    warningCard.classList.toggle("expanded");
  });

  // Privacy & Terms Link click event (footer)
  document
    .getElementById("footer__terms")
    ?.addEventListener("click", () => showPrivacyTermsInfo());
  // Privacy & Terms CLose button
  document
    .getElementById("terms-close-btn")
    ?.addEventListener("click", () => hidePrivacyTermsInfo());

  // Privacy & Terms - Accordion sections events
  const accordionSections = document.querySelectorAll(".accordion-section");
  const accordionSectionHeaders = document.querySelectorAll(
    ".accordion-section h4",
  );
  accordionSectionHeaders.forEach((sectionHeader) => {
    sectionHeader.addEventListener("click", (e) => {
      // expand or collapse the parent section
      const parentSection = e.target.closest(".accordion-section");
      if (parentSection.classList.contains("expanded")) {
        parentSection.classList.remove("expanded");
      } else {
        // collapse all other sections
        accordionSections.forEach((section) => {
          if (section !== parentSection) {
            section.classList.remove("expanded");
          }
        });
        parentSection.classList.add("expanded");
      }
    });
  });

  /**************** APP INITIALIZATION********************/

  // in Services section, mark the first service detail as active
  // (tablet & desktop resolution only)
  if (window.innerWidth >= 768) {
    setDefaultService();
  }

  // currentSectionIndex = 3; // TEST MODE ONLY
  renderSections();
});
