// utility function - returns a title-case version of a string
function toTitleCase(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}
// validation parameters
const INPUT_MAX_LENGTH = 50;
const MESSAGE_MAX_LENGTH = 400;

// validation constraints
const CONSTRAINTS = {
  INPUT_REQUIRED: "REQUIRED",
  INPUT_FORMAT_EMAIL: "EMAIL",
};

export class FormField {
  constructor(id) {
    this.id = id;
    try {
      this._domElement = document.getElementById(id);
      if (!this._domElement) {
        throw new Error("Form field with id " + id + " not found");
      }
      // This is not necessary for our use case:
      // add a change event listener (also can use unput event for text fields)
      // this._domElement.addEventListener("change", (e) => {
      //   this._value = e.target.value;
      // });
    } catch (err) {
      console.error(err);
    }
  }
  // public property getters
  get value() {
    return this._domElement.value;
  }
  get type() {
    return this._domElement.type;
  }
  get name() {
    return this._domElement.name;
  }
  get title() {
    return toTitleCase(this._domElement.name); // name in title case
  }
  get validationError() {
    return this.validate(this._getAutoConstraints());
  }
  get isValid() {
    return this.validate(this._getAutoConstraints()) === "";
  }
  // internal property getters
  get _isBlank() {
    return this._domElement.value === "";
  }
  get _isRequired() {
    return (
      this._domElement.required ||
      this._domElement.hasAttribute("required") ||
      this._domElement.classList.contains("required")
    );
  }
  get _isText() {
    return this._domElement.type === "text";
  }
  get _isEmail() {
    return this._domElement.type === "email";
  }
  get _isEmailValid() {
    return this._domElement.validity.valid;
  }
  get _isMaxLengthExceeded() {
    return this._domElement.value.length > INPUT_MAX_LENGTH;
  }
  get _isMessageMaxLengthExceeded() {
    return this._domElement.value.length > MESSAGE_MAX_LENGTH;
  }
  /**
   * Derives the constraints based on the form field properties and classes.
   *
   * @return {array} The array of constraints or an empty array.
   */
  get _autoConstraints() {
    const constraints = [];
    if (this._isRequired) {
      constraints.push(CONSTRAINTS.INPUT_REQUIRED);
    }
    if (this._isEmail) {
      constraints.push(CONSTRAINTS.INPUT_FORMAT_EMAIL);
    }
    return constraints;
  }

  // PUBLIC METHODS

  /**
   * Trim field value in the form.
   *
   * @return {string} trimmedValue - the trimmed value of the field
   */
  trim() {
    // trim field value in the form
    const currentValue = this.value;
    const trimmedValue = this.value.trim();
    // note: type="email" field value always gets trimmed internally
    if (currentValue !== trimmedValue || this._isEmail) {
      this._domElement.value = trimmedValue;
    }
    return trimmedValue; // return value is not always used
  }
  /**
   * Validate the field value based on the given constraints.
   *
   * @param {Array} constraints - The constraints to validate against
   * @return {string} The error message, if any
   */
  validate(constraints) {
    // validate the trimmed field value
    const trimmedValue = this.trim();
    // for required field, return the 'required' error message
    if (
      constraints.includes(CONSTRAINTS.INPUT_REQUIRED) &&
      trimmedValue === ""
    ) {
      return this.title + " is required.";
    }
    // for ALL text and email fields regardless of constraints, validate the max value length
    if (
      (this._isText || this._isEmail) &&
      trimmedValue.length > INPUT_MAX_LENGTH
    ) {
      return (
        this.title + " exceeds the max length of " + INPUT_MAX_LENGTH + "."
      );
    }
    // for message field, validate the max value length
    if (this.type === "textarea" && trimmedValue.length > MESSAGE_MAX_LENGTH) {
      return (
        this.title + " exceeds the max length of " + MESSAGE_MAX_LENGTH + "."
      );
    }
    // for email field, validate the input value and return the 'email' error message
    if (constraints.includes(CONSTRAINTS.INPUT_FORMAT_EMAIL)) {
      const emailValidationResult =
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+\.[a-zA-Z][a-zA-Z0-9-]{0,5}[a-zA-Z]$/.test(
          this.value,
        );
      if (!emailValidationResult) {
        return "Invalid email address.";
      }
    }
    return "";
  }
  /**
   * Validate with constraints derived from form field properties and classes.
   *
   * @return {string} the error message or an empty string, if no error
   */
  validateWithAutoConstraints() {
    return this.validate(this._autoConstraints);
  }
}

/**
 * Retrieves all input and textarea elements within a form and returns them as
 * an object with the element id as the key and the element object as the value.
 *
 * @param {HTMLElement} form - The form element to search for input elements.
 * @return {Object} inputElements - The input elements within the form as an object.
 */
function getInputElementsInForm(form) {
  const inputElements = {};
  if (form) {
    const inputFields = form.querySelectorAll("input, textarea");
    inputFields.forEach((element) => {
      if (
        element.id &&
        (element.tagName === "INPUT" || element.tagName === "TEXTAREA")
      ) {
        inputElements[element.id] = element;
      }
    });
  }
  return inputElements;
}

export class FormValidation {
  constructor(formId) {
    try {
      this._form = document.getElementById(formId);
      if (!this._form) {
        throw new Error("Form with id " + formId + " not found");
      }
      this._FormFields = [];
      // get all input and textarea elements
      const inputElements = getInputElementsInForm(this._form);
      Object.values(inputElements).forEach((element) => {
        const field = new FormField(element.id);
        this._FormFields.push(field);
        // console.log(field.title, "constraints: ", field._autoConstraints);
      });
    } catch (err) {
      console.error(err);
    }
  }
  // public methods
  trimAll() {
    this._FormFields.forEach((field) => {
      field.trim();
    });
  }
  validateAllWithAutoConstraints() {
    const validationResult = this._FormFields.reduce((result, field) => {
      const fieldValodationResult = field.validateWithAutoConstraints();
      if (fieldValodationResult) {
        result.push(fieldValodationResult);
      }
      return result;
    }, []);
    return validationResult;
  }
}
