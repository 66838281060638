/* eslint-env browser */
// formAlerts.mjs
import { TIMEOUT_ALERT_SUCCESS } from "./config.js";

const alertDlg = document.getElementById("contact-form-dialog");
const alertContent = alertDlg.querySelector(".content-wrapper");

const btnSubmit = document.getElementById("contact-form__btn-submit");
let alertTimer;

export const ALERT_TYPES = {
  ERROR: "error",
  SUCCESS: "success",
  INFO: "info",
};

export let isModalOpen = false; // show or hide modal dialog

export function showFormAlert(
  alertType,
  alertTitle,
  alertMessage,
  isModal = true,
) {
  if (isModalOpen) return; // don't show alert if modal is open

  // for error alert convert the array of of errors to HTML list
  if (alertType === ALERT_TYPES.ERROR && typeof alertMessage === "object") {
    alertMessage =
      "<ul>" +
      alertMessage.map((item) => `<li>${item}</li>`).join("") +
      "</ul>";
  }

  alertContent.innerHTML = `
      <h4 class="dialog-title">${alertTitle}</h4>
      <div class="dialog-message">${alertMessage}</div>
      <button type="button" class="btn--dlg-circle">`;
  // disable form submit button
  alertDlg.classList.add(alertType);
  if (isModal) {
    btnSubmit.disabled = true;
    alertDlg.showModal();
  } else {
    alertDlg.show();
  }
  // disable handling scrolling and touch swipes
  isModalOpen = true;

  // for success alert auto-close after 3 seconds
  if (alertType === ALERT_TYPES.SUCCESS) {
    alertTimer = setTimeout(hideFormAlert, TIMEOUT_ALERT_SUCCESS);
  }
}

export function hideFormAlert() {
  alertContent.innerHTML = "";
  alertDlg.close();
  alertDlg.classList.remove(ALERT_TYPES.ERROR, ALERT_TYPES.SUCCESS);
  // re-enable form submit button
  btnSubmit.disabled = false;
  // re-enable handling scrolling and touch swipes
  isModalOpen = false;
  clearTimeout(alertTimer);
}

/**** Event listeners ****/
// click anywhere on the alert element to close
alertContent.addEventListener("click", hideFormAlert);

// hit ESC while the alert is open to close
alertDlg.addEventListener("keydown", (e) => {
  if (e.key === "Escape") {
    hideFormAlert();
  }
});
