export const API_URL =
  "https://us-central1-sowa-52396.cloudfunctions.net/api/contactform";
// FOR TESTING ONLY:
// export const API_URL =
//   "http://127.0.0.1:5001/sowa-52396/us-central1/api/contactform";

export const TIMEOUT_SUBMIT = 15000; // submission times out after 15 sec
export const TIMEOUT_ALERT_SUCCESS = 2200; // success alert auto-close after ~2 sec
export const EMAIL_REGEX =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+\.[a-zA-Z][a-zA-Z0-9-]{0,5}[a-zA-Z]$/;
